import React from "react"
import { Col, Container, Row } from "react-bootstrap";
import Layout from "../layout";
import HomeImage from "../images/bg.jpg";
import LogoImage from "../images/logo-gold.png";
import { useStaticQuery, graphql } from "gatsby";

import { GatsbyImage, getImage } from "gatsby-plugin-image"

// markup
const IndexPage = () => {
  // console.log("c", HomeImage);

  // const data = useStaticQuery(imageQuery);
  // // console.log("ads", {data});
  // const image = getImage(data.file);
  // // console.log("ads", {data, image});
  return (<Layout>
    <div style={{
      height: "100vh", width: "100%",
      backgroundImage: `url(${HomeImage})`,
      backgroundSize: "cover",
      backgroundPosition: "right bottom"
    }}/>
    <div className="logo-banner">
      <Container fluid>
        <Row className="logo-row">
          <Col className="ml-auto" xs={"auto"}>
            <img src={LogoImage} />
          </Col>
        </Row>
      </Container>
    </div>
  </Layout>);
}



// export const imageQuery = graphql`
// query {
//   file(relativePath: { eq: "./bg.jpg" }) {
//     id
//     childImageSharp {
//       gatsbyImageData(
//         width: 1920
//         placeholder: BLURRED
//         formats: [AUTO, WEBP, AVIF]
//       )
//     }
//   }
// }`;

export default IndexPage
